import { FC, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ClientFormUser } from '../../models/ClientFormUser';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import ClientFormService from '../../services/ClientFormService';
import { ContributerSelectSize, ContributorSelect } from './ContributorSelect';
import { OwnershipDisplayType, OwnershipProps } from './OwnershipProps';
import PermissionsModal from './PermissionsModal';
import { ImageSize, ProfileImageStack } from './ProfileImageStack';
import ObjectUtils from '../../utils/ObjectUtils';
import { ClientFormUserRoleValues } from '../../models/ClientFormUserRoles';
import UsersGroupSolidIcon from '../shared/icon/UsersGroupSolidIcon';
import { Access } from '../../models/Access';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';

type FormOwnershipProps = {
  onCurrentUserRoleChange?: (user: ClientFormUser) => void;
  onRemoveCurrentUser?: () => void;
  onCreatorRoleChange?: (role: ClientFormUserRoleValues | null) => void;
  onUsersChange?: (users: ClientFormUser[]) => void;
  requiresApproval?: boolean;
  requiresValidation?: boolean;
  requiresOwner?: boolean;
  shouldValidateRoles?: boolean;
  modalHeading?: string;
  modalDescription?: string;
  creatorRole?: ClientFormUserRoleValues;
  open?: boolean;
  onClose?: () => void;
  accessType?: Access;
  onAccesChange?: (value: Access) => void;
  displayLimit?: number;
} & OwnershipProps;

const FormOwnership: FC<FormOwnershipProps> = (props) => {
  const {
    associatedId,
    users = [],
    size = OwnershipDisplayType.Default,
    viewOnly = false,
    onCurrentUserRoleChange,
    onRemoveCurrentUser,
    onUsersChange,
    onCreatorRoleChange,
    requiresApproval,
    requiresValidation,
    modalHeading,
    formStepId,
    modalDescription,
    creatorRole,
    open,
    onClose,
    accessType,
    onAccesChange,
    requiresOwner,
    shouldValidateRoles,
    displayLimit,
  } = props;
  const loggedInUser = useRecoilValue(currentUserAtom);
  const [showPermisionsModal, setShowPermisionsModal] = useState(false);
  const [accessTypeInternal, setAccess] = useState(accessType);

  const addOrUpdateUsers = (changedUsers: ClientFormUser[]) => {
    if (associatedId) {
      if (accessTypeInternal !== undefined && accessType !== accessTypeInternal) {
        onAccesChange && onAccesChange(accessTypeInternal);
      }
      return ClientFormService.addOrUpdateFormUsers(associatedId, changedUsers, formStepId).then(() => {
        const currentFormUser = users.find((x) => x.id === loggedInUser?.id);
        const changedCurrentFormUser = changedUsers.find((x) => x.id === loggedInUser?.id);
        const isCurrentFormUserRemoved =
          currentFormUser && !changedUsers.find((x) => x.id === currentFormUser?.id && (formStepId ? x.formSectionId === formStepId : true));

        if (onRemoveCurrentUser && isCurrentFormUserRemoved) {
          onRemoveCurrentUser();
        }
        if (
          onCurrentUserRoleChange &&
          changedCurrentFormUser &&
          currentFormUser?.role !== changedCurrentFormUser?.role &&
          currentFormUser?.formSectionId === changedCurrentFormUser.formSectionId
        ) {
          onCurrentUserRoleChange(changedCurrentFormUser);
        }
        onUsersChange && onUsersChange(changedUsers);
      });
    } else {
      onUsersChange && onUsersChange(changedUsers);
    }
    return Promise.resolve();
  };

  const usersToDisplay = useMemo(() => {
    const result = ObjectUtils.DeepClone(users).sort((a, b) => (a.role === b.role ? 0 : a.role < b.role ? 1 : -1));
    return result;
  }, [users]);

  const triggerModalFromExternal = useMemo(() => open !== undefined && onClose, [onClose, open]);

  return (
    <div className="flex !h-fit max-w-xs items-center" data-cy="form-ownership">
      {size === OwnershipDisplayType.Default && (
        <div className={`${viewOnly ? '' : 'mr-4'}`}>
          <ProfileImageStack users={usersToDisplay} displayLimit={displayLimit} />
        </div>
      )}
      {size === OwnershipDisplayType.Tiny && (
        <div>
          <ProfileImageStack users={usersToDisplay} size={ImageSize.S} displayLimit={displayLimit} />
        </div>
      )}
      {size === OwnershipDisplayType.Icon && (
        <span
          className="text-dpm-12 group flex cursor-pointer items-center gap-1"
          {...mouseAndKeyboardCallbackProps((e) => {
            e.stopPropagation();
            setShowPermisionsModal(true);
          })}
        >
          <UsersGroupSolidIcon className="h-4" />{' '}
          <span className="-mb-[2px] font-medium group-hover:underline">{usersToDisplay.length.toString()}</span>
        </span>
      )}
      {!viewOnly && (
        <div className={`${size !== OwnershipDisplayType.Tiny && '-ml-2'} flex`}>
          <div className="flex h-full">
            <div className="flex flex-col self-center">
              {size !== OwnershipDisplayType.Icon && !triggerModalFromExternal && (
                <ContributorSelect
                  onClick={() => setShowPermisionsModal(true)}
                  size={size === OwnershipDisplayType.Tiny ? ContributerSelectSize.S : ContributerSelectSize.M}
                />
              )}
              <PermissionsModal
                formStepId={formStepId}
                requiresApproval={requiresApproval}
                requiresValidation={requiresValidation}
                requiresOwner={requiresOwner}
                users={users}
                open={showPermisionsModal || !!open}
                onClose={() => {
                  onClose && onClose();
                  setShowPermisionsModal(false);
                }}
                onAddorUpdate={addOrUpdateUsers}
                heading={modalHeading}
                description={modalDescription}
                onCreatorRoleChange={onCreatorRoleChange}
                creatorRole={creatorRole}
                accessType={accessTypeInternal}
                onAccesChange={(value) => setAccess(value)}
                shouldValidateRoles={shouldValidateRoles}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormOwnership;
