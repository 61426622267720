import { FC, MouseEvent } from 'react';
import AddUserIcon from '../shared/icon/AddUserIcon';

type ValidatorSelectProps = {
  size?: ContributerSelectSize;
  className?: string;
  onClick: (e: MouseEvent<HTMLElement>) => void;
};

export enum ContributerSelectSize {
  S,
  M,
}

export const ContributorSelect: FC<ValidatorSelectProps> = (props) => {
  const { size = ContributerSelectSize.M, className, onClick } = props;

  let iconSizeCss = 'w-5 h-5';
  let circleSizeCss = 'w-8 h-8';
  switch (size) {
    case ContributerSelectSize.S:
      {
        iconSizeCss = 'w-4 h-4';
        circleSizeCss = 'w-5 h-5';
      }
      break;
    default:
    case ContributerSelectSize.M:
      {
        iconSizeCss = 'w-5 h-5';
        circleSizeCss = 'w-6 h-6';
      }
      break;
  }

  return (
    <div
      data-cy="toggle-contributer-add"
      onClick={onClick}
      className={`bg-gray-1 ${circleSizeCss} ${className} relative z-20 flex cursor-pointer items-center justify-center rounded-full text-white`}
    >
      <AddUserIcon className={iconSizeCss} />
    </div>
  );
};
