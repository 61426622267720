import { ClientFormUser } from '../../models/ClientFormUser';

export type OwnershipProps = {
  associatedId?: string;
  formStepId?: string;
  users?: ClientFormUser[];
  size?: OwnershipDisplayType;
  viewOnly?: boolean;
  caption?: string;
};

export enum OwnershipDisplayType {
  Default,
  Tiny,
  Icon,
}
